import { PageResult } from './pageResult';
import { Website } from './website';

export class ProfessionalSearch {
  id: number;
  name: string;
  registrationNumber: string;
  address: string;
  workplace: string;
  benefits: string[] = [];
  provinces: string[] = [];
  professions: string[] = [];
  benefitSearchGroups: BenefitSearchGroup[] | null;

  pageNumber: number;
  pageSize: number;
  pageResult: PageResult;
}

export class BenefitSearchGroup {
  benefit: string | null;
  provinces: {
    province: string;
    professions: string[];
  }[];
}
