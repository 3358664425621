import { createReducer, on } from '@ngrx/store';
import {
  setProvidersSearchOptions,
  setProvidersSelectedBenefit,
  setProvidersResultsDataByBenefit,
  setProvidersResultsFailure,
  setSelectedProviderDetails,
  setHasMinimumSearchQueryParams,
} from './providers.actions';
import { initialProviderState } from './providers.state';

export const providersReducer = createReducer(
  initialProviderState,
  on(setProvidersSearchOptions, (state, { providersSearchOptions }) => ({
    ...state,
    providersSearchOptions,
  })),
  on(setProvidersSelectedBenefit, (state, { providersSelectedBenefit }) => ({
    ...state,
    providersSelectedBenefit,
  })),
  on(
    setProvidersResultsDataByBenefit,
    (state, { benefit, providersResultsData }) => ({
      ...state,
      providersResultsData: {
        ...state.providersResultsData,
        [benefit]: providersResultsData, // Add or update the results for the given benefit
      },
    })
  ),
  on(setProvidersResultsFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(setSelectedProviderDetails, (state, { selectedProviderDetails }) => ({
    ...state,
    selectedProviderDetails,
  })),
  on(
    setHasMinimumSearchQueryParams,
    (state, { hasMinimumSearchQueryParams }) => ({
      ...state,
      hasMinimumSearchQueryParams,
    })
  )
);
