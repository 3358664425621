import { Component } from '@angular/core';
import { filter, map, Observable } from 'rxjs';

import { AuthService } from '../../../services/auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { setActivePath } from '../../../state/app/app.actions';

@Component({
  selector: 'nav-bar',

  templateUrl: './nav-bar.component.html',
  styleUrl: './nav-bar.component.scss',
})
export class NavBarComponent {
  activePath$: Observable<string>;
  currentPath: string;
  isAuthenticated$!: Observable<boolean>;

  navLinks = [
    { label: 'Providers', path: '/providers' },
    { label: 'Professionals', path: '/professionals' },
    { label: 'Disciplines', path: '/disciplines' },
  ];

  constructor(
    private authService: AuthService,
    private router: Router,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.isAuthenticated$ = this.authService.isAuthenticated$();

    // Filter for NavigationEnd events and extract current path
    this.activePath$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.router.url.split('?')[0])
    );

    this.activePath$.subscribe((currentPath) => {
      this.currentPath = currentPath;
      this.store.dispatch(setActivePath({ activePath: currentPath }));
    });
  }

  navigate(path: string): void {
    this.router.navigate([path]);
  }

  onLogout(): void {
    this.authService.logout();
    this.router.navigate(['/login']);
  }
}
