import { Component, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';

import { Professional } from '../../../models/professional';
import { environment } from '../../../../environments/environment';
import { selectProfessionalSelectedBenefit } from '../../../state/professionals/professionals.selectors';
@Component({
  selector: 'app-professional-details-view',
  templateUrl: './professional-details-view.component.html',
  styleUrl: './professional-details-view.component.scss',
})
export class ProfessionalDetailsViewComponent {
  @Input() professional!: Professional;
  @Input() province!: string;

  private subscription = new Subscription();
  resolvedSelectedBenefit!: string;
  environment = environment;

  constructor(private store: Store, private router: Router) {}

  ngOnInit(): void {
    const benefitSub = this.store
      .select(selectProfessionalSelectedBenefit)
      .subscribe((benefit) => {
        this.resolvedSelectedBenefit = benefit;
      });
    this.subscription.add(benefitSub);
  }

  navigateToDisciplines(professional: Professional) {
    const professionalData = {
      name: professional.name,
      benefit: [this.resolvedSelectedBenefit],
      province: [this.province],
      professional,
    };

    this.router.navigate(['/disciplines'], {
      state: { disciplineSearch: professionalData },
      queryParams: {
        ben: this.resolvedSelectedBenefit,
        prov: this.province,
        n: professional.name,
      },
    });
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
