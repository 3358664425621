import { createAction, props } from '@ngrx/store';
import { Professional, ProfessionalSearch } from '../../models';

export const setProfessionalSelectedBenefit = createAction(
  '[Professionals state] Set Professional Selected Benefit',
  props<{ professionalSelectedBenefit: string }>()
);

export const setProfessionalsSearchOptions = createAction(
  '[Provider state] Set Professional Search Options',
  props<{ professionalsSearchOptions: ProfessionalSearch }>()
);

export const setProfessionalsResultsByBenefit = createAction(
  '[Professionals state] Set Professionals Results Data',
  props<{ benefit: string; professionalsResults: Professional[] }>()
);

export const setSelectedProfessionalDetails = createAction(
  '[Professionals state] Set Selected Professional Details',
  props<{ selectedProfessionalDetails: Professional }>()
);

export const setHasMinimumSearchQueryParams = createAction(
  '[Professionals state] Set Has Minimum Search Query Params',
  props<{ hasMinimumSearchQueryParams: boolean }>()
);
