import { createSelector, createFeatureSelector } from '@ngrx/store';
import { DisciplinesState } from './disciplines.state';

export const selectDisciplinesState =
  createFeatureSelector<DisciplinesState>('disciplines');

export const selectDisciplinesSearchOptions = createSelector(
  selectDisciplinesState,
  (state) => state.disciplinesSearchOptions
);

export const selectDisciplinesSelectedBenefit = createSelector(
  selectDisciplinesState,
  (state) => state.disciplineSelectedBenefit
);

export const selectDisciplinesResultsByBenefit = (benefit: string) =>
  createSelector(
    selectDisciplinesState,
    (state) => state.disciplinesResultsData[benefit]
  );

export const selectDisciplinesResultsError = createSelector(
  selectDisciplinesState,
  (state) => state.error
);

export const selectSelectedDisciplineDetails = createSelector(
  selectDisciplinesState,
  (state) => state.selectedDisciplineDetails
);

export const selectHasMinimumSearchQueryParams = createSelector(
  selectDisciplinesState,
  (state) => state.hasMinimumSearchQueryParams
);
