import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { Discipline } from './../../../models/discipline';
import { environment } from '../../../../environments/environment';
import { selectDisciplinesSelectedBenefit } from '../../../state/disciplines/disciplines.selectors';

@Component({
  selector: 'discipline-details-view',
  templateUrl: './discipline-details-view.component.html',
  styleUrl: './discipline-details-view.component.scss',
})
export class DisciplineDetailsViewComponent {
  selectedBenefit$: Observable<string>;
  environment = environment;
  @Input() discipline!: Discipline;
  @Input() province!: string;

  selectedDiscipline = null;
  professionalInternalUrl: string = '';
  resolvedSelectedBenefit!: string;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.selectedBenefit$ = this.store.select(selectDisciplinesSelectedBenefit);
  }
}
