import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from './guards/auth.guard';
import { LoginComponent } from './pages/login/login.component';
import { ProviderListComponent } from './pages/provider/provider-list/provider-list.component';
import { ProfessionalListComponent } from './pages/professional/professional-list/professional-list.component';
import { DisciplineListComponent } from './pages/discipline/discipline-list/discipline-list.component';

const routes: Routes = [
  { path: '', redirectTo: '/providers', pathMatch: 'full' },
  {
    path: 'providers',
    component: ProviderListComponent,
    canActivate: [authGuard],
  },
  {
    path: 'professionals',
    component: ProfessionalListComponent,
    canActivate: [authGuard],
  },
  {
    path: 'disciplines',
    component: DisciplineListComponent,
    canActivate: [authGuard],
  },
  { path: 'login', component: LoginComponent, canActivate: [authGuard] },
  { path: '**', redirectTo: '/providers' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
