import { Component, Input, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-safe-html-viewer',
  templateUrl: './safe-html-viewer.component.html',
  styleUrl: './safe-html-viewer.component.scss',
  encapsulation: ViewEncapsulation.None, // Disable view encapsulation
})
export class SafeHtmlViewerComponent {
  @Input() rawHtml!: string;
  sanitizedHtml: SafeHtml;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.sanitizedHtml = this.sanitizeHtml(this.rawHtml);
  }

  sanitizeHtml(html: string): SafeHtml {
    const safeHtml = html.replace(/[\u0000-\u001F\u007F-\u009F]/g, '');
    return this.sanitizer.bypassSecurityTrustHtml(safeHtml);
  }

  handleShowMore(event: Event): void {
    const target = event.target as HTMLElement;
    if (target && target.classList.contains('readMore')) {
      const toggleDiv = document.getElementById('DisciplineDiv');
      if (toggleDiv) {
        const isHidden = toggleDiv.style.display === 'none';
        toggleDiv.style.display = isHidden ? 'block' : 'none';
        target.textContent = isHidden ? '...read less' : '...read more';
      }
    }
  }
}
