import { SearchResult } from './../../../models/disciplineSearch';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Subscription, switchMap, combineLatest } from 'rxjs';
import { Store } from '@ngrx/store';

import { NotificationService } from '../../../services/notification.service';
import { DisciplineService } from '../../../services/discipline.service';
import {
  DisciplineSearch,
  BenefitSearchGroup,
} from '../../../models/disciplineSearch';
import { Discipline } from '../../../models/discipline';
import {
  setDisciplineSelectedBenefit,
  setDisciplinesResultsDataByBenefit,
  setSelectedDisciplineDetails,
} from '../../../state/disciplines/disciplines.actions';
import {
  selectDisciplinesResultsByBenefit,
  selectDisciplinesSearchOptions,
  selectDisciplinesSelectedBenefit,
  selectSelectedDisciplineDetails,
} from '../../../state/disciplines/disciplines.selectors';
import { hideLoading, showLoading } from '../../../state/app/app.actions';

@Component({
  selector: 'app-discipline-list',
  templateUrl: './discipline-list.component.html',
  styleUrl: './discipline-list.component.scss',
})
export class DisciplineListComponent implements OnInit, AfterViewInit {
  private subscription = new Subscription();
  private userInteracted = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  matDataSource: MatTableDataSource<Discipline> = new MatTableDataSource();
  disciplineSearchParam: DisciplineSearch = new DisciplineSearch();
  disciplineSearch: DisciplineSearch = new DisciplineSearch();

  loading: boolean = false;
  disciplines: Discipline[] = [];

  openDetailsByID?: number = null;
  selectedDiscipline: Discipline = null;
  selectedBenefit?: string = '';
  selectedBenefitTabIndex: number = 0;

  benefitSearchGroupsSelected: BenefitSearchGroup[];
  filteredProfessions: string[] = [];
  filteredProvinces: string[] = [];

  selectedRowIndex: number = 0;
  displayedColumns: string[] = ['results'];
  showConcernsOnly = false;

  resultsMessage: string = '';
  initalSearchMessage: string = 'Select some search options to begin';
  noResultsMessage: string = 'No results found';
  noResultsWithConcerns: string = `The result doesn't contain items with concerns`;

  findDisciplinesByStateParams: DisciplineSearch = null;

  constructor(
    private disciplineService: DisciplineService,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.resultsMessage = this.initalSearchMessage;
    const combinedbenefitSubscription = combineLatest([
      this.store.select(selectDisciplinesSearchOptions),
      this.store.select(selectDisciplinesSelectedBenefit),
    ])
      .pipe(
        switchMap(([searchOptions, benefit]) => {
          if (searchOptions.provinces.length > 0) {
            this.disciplineSearchParam = searchOptions;
          }
          this.selectedBenefit = benefit;
          this.disciplineSearch.benefits = [benefit];
          this.updateFiltersByBenefitSelected();
          this.selectedBenefitTabIndex =
            this.disciplineSearchParam.benefits.indexOf(benefit);
          return this.store.select(selectDisciplinesResultsByBenefit(benefit));
        })
      )
      .subscribe((data) => {
        this.disciplines = [];
        this.matDataSource.data = [];
        if (this.disciplineSearch.provinces.length > 0) {
          // if province is selected, display the preloaded data, if not, ask for a new search
          if (data?.length == 0) {
            this.resultsMessage = this.noResultsMessage;
          }
          this.disciplines = data || [];
          this.matDataSource.data = data;
        }
        this.matDataSource.paginator = this.paginator;
        this.autoSelectItemDetails();
      });

    const selectedProviderSub = this.store
      .select(selectSelectedDisciplineDetails)
      .subscribe((discipline) => {
        this.selectedDiscipline = discipline;
      });

    this.loadByIniaitalSearchQueryParams();
    this.subscription.add(combinedbenefitSubscription);
    this.subscription.add(selectedProviderSub);
  }

  ngAfterViewInit() {
    this.matDataSource.paginator = this.paginator;
  }

  loadByIniaitalSearchQueryParams() {
    this.findDisciplinesByStateParams = history.state.disciplineSearch;
    this.openDetailsByID = Number(this.route.snapshot.queryParamMap.get('id'));
    const name: string = this.route.snapshot.queryParamMap.get('n');
    const benefit: string = this.route.snapshot.queryParamMap.get('ben');
    const province: string = this.route.snapshot.queryParamMap.get('prov');
    const profession: string = this.route.snapshot.queryParamMap.get('prof');

    this.disciplineSearch.name = name;
    this.disciplineSearch.provinces = province ? [province] : [];
    this.disciplineSearch.professions = profession ? [profession] : [];

    if (benefit || !this.selectedBenefit) {
      this.store.dispatch(
        setDisciplineSelectedBenefit({
          disciplineSelectedBenefit: benefit
            ? benefit // set the one in params
            : this.disciplineSearchParam.benefits[0], // set the first in searchOptions
        })
      );
    }

    if (this.findDisciplinesByStateParams) {
      this.searchDisciplines2(this.findDisciplinesByStateParams);
    } else if (this.hasMinimumSearchQueryParams && !this.loading) {
      if (this.disciplines.length == 0) {
        this.search();
      }
    }
  }

  searchDisciplines(disciplineSearch: DisciplineSearch) {
    this.loading = true;
    this.store.dispatch(showLoading());
    this.disciplineService
      .searchDisciplines(disciplineSearch)
      .subscribe((disciplinesResults) => {
        this.store.dispatch(
          setDisciplinesResultsDataByBenefit({
            benefit: this.selectedBenefit,
            disciplinesResults: disciplinesResults,
          })
        );
        this.store.dispatch(hideLoading());
        this.loading = false;
        const notification_message =
          disciplinesResults.length > 0
            ? 'Data fetched Successfully'
            : 'No results';
        this.notificationService.showNotification(
          notification_message,
          'Close',
          'success'
        );
      });
  }

  searchDisciplines2(findDisciplinesByStateParams: DisciplineSearch) {
    this.loading = true;
    this.store.dispatch(showLoading());
    this.disciplineService
      .searchDisciplines2(findDisciplinesByStateParams)
      .subscribe((disciplinesMatchedResults: DisciplineSearch) => {
        const eventsResults = disciplinesMatchedResults.searchResult?.events;
        this.disciplines = eventsResults;
        this.store.dispatch(
          setDisciplinesResultsDataByBenefit({
            benefit: this.selectedBenefit,
            disciplinesResults: eventsResults || [],
          })
        );
        this.store.dispatch(hideLoading());
        this.loading = false;
        const notification_message =
          eventsResults.length > 0
            ? 'Data fetched Successfully'
            : `No results matched ${findDisciplinesByStateParams.name}`;
        this.notificationService.showNotification(
          notification_message,
          'Close',
          'success'
        );
      });
  }

  private autoSelectItemDetails() {
    const initialSelectedDiscipline = this.selectedDiscipline;
    let newSelectedDiscipline = null;

    const loadedQueryViaSearchParams =
      this.openDetailsByID && this.disciplines.length > 0;

    const loadedQueryViaStateParams =
      this.findDisciplinesByStateParams && this.disciplines.length > 0;

    if (loadedQueryViaSearchParams || loadedQueryViaStateParams) {
      newSelectedDiscipline =
        this.disciplines.find((p) => p.id === this.openDetailsByID) ??
        this.disciplines[0];
    } else if (initialSelectedDiscipline && this.disciplines.length > 0) {
      newSelectedDiscipline = this.disciplines[0];
    } else {
      newSelectedDiscipline = null;
    }

    if (initialSelectedDiscipline != newSelectedDiscipline) {
      this.store.dispatch(
        setSelectedDisciplineDetails({
          selectedDisciplineDetails: newSelectedDiscipline,
        })
      );
    }
  }

  search() {
    if (!this.disciplineSearch.benefits[0]) {
      this.disciplineSearch.benefits = [this.selectedBenefit];
    }
    this.searchDisciplines(this.disciplineSearch);
    this.onCloseDetails();
    this.setRoute();
  }

  updateResutsByConcernsFilter() {
    if (this.showConcernsOnly) {
      this.matDataSource.data = this.disciplines.filter(
        (row: any) => row.hasConcerns === true
      );
      if (this.disciplines.length > 0 && this.matDataSource.data.length == 0) {
        this.resultsMessage = this.noResultsWithConcerns;
        if (this.selectedDiscipline) {
          this.onCloseDetails();
        }
      }
    } else {
      this.matDataSource.data = this.disciplines;
    }
  }

  clear() {
    this.disciplineSearch = new DisciplineSearch();
    this.setRoute();
    this.onCloseDetails();
    this.resultsMessage = this.initalSearchMessage;
    this.disciplines = [];
    this.matDataSource.data = [];
  }

  private setRoute() {
    this.router.navigate([], {
      queryParams: {
        ben: this.disciplineSearch.benefits,
        prof: this.disciplineSearch.professions,
        prov: this.disciplineSearch.provinces,
        n: this.disciplineSearch.name,
      },
    });
  }

  onBenefitTabIndexChange(index: number): void {
    const newBenefit = this.disciplineSearchParam.benefits[index];
    this.store.dispatch(
      setDisciplineSelectedBenefit({
        disciplineSelectedBenefit: newBenefit,
      })
    );
  }

  resetPreloadParams() {
    this.disciplineSearch.provinces = [];
    this.disciplineSearch.professions = [];
    this.disciplineSearch.name = null;
  }

  openDetails(discipline: Discipline, index: number): void {
    this.selectedRowIndex = this.getActualIndex(index);
    this.store.dispatch(
      setSelectedDisciplineDetails({
        selectedDisciplineDetails: discipline,
      })
    );
  }

  onCloseDetails(): void {
    this.store.dispatch(
      setSelectedDisciplineDetails({
        selectedDisciplineDetails: null,
      })
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.matDataSource.filter = filterValue.trim().toLowerCase();

    if (this.matDataSource.paginator) {
      this.matDataSource.paginator.firstPage();
    }
  }

  onKeydown(event: KeyboardEvent): void {
    if (this.matDataSource.data?.length === 0) return;

    const maxIndex = this.getMaxIndexOnCurrentPage();
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    if (event.key === 'ArrowDown') {
      this.selectedRowIndex = Math.min(this.selectedRowIndex + 1, maxIndex);
    } else if (event.key === 'ArrowUp') {
      this.selectedRowIndex = Math.max(this.selectedRowIndex - 1, startIndex);
    }

    const newSelectedDiscipline =
      this.matDataSource.data[this.selectedRowIndex];
    this.store.dispatch(
      setSelectedDisciplineDetails({
        selectedDisciplineDetails: newSelectedDiscipline,
      })
    );
  }

  getActualIndex(index: number): number {
    return this.paginator.pageIndex * this.paginator.pageSize + index;
  }

  private getMaxIndexOnCurrentPage(): number {
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    const endIndex = Math.min(
      startIndex + this.paginator.pageSize - 1,
      this.matDataSource.data.length - 1
    );
    return endIndex;
  }

  onUserInteractChangeProvice(): void {
    this.userInteracted = true;
  }

  onProvinceSelected(value?: string): void {
    if (this.userInteracted) {
      this.disciplineSearch.professions = [];
      this.userInteracted = false;
    }
    // disciplines page is not currenty having distinct professions per provice, therefore value is not implemented in this function
    this.filteredProfessions = [
      ...new Set(
        this.benefitSearchGroupsSelected?.flatMap((item) => item.professions)
      ),
    ].sort();
  }

  private updateFiltersByBenefitSelected(): void {
    const benefitSearchGroupsSelected =
      this.disciplineSearchParam.benefitSearchGroups?.filter(
        (benefitGroup) => benefitGroup.benefit === this.selectedBenefit
      );

    this.filteredProvinces = [
      ...new Set(
        benefitSearchGroupsSelected?.flatMap((item) => item.provinces)
      ),
    ].sort();

    this.benefitSearchGroupsSelected = benefitSearchGroupsSelected;
    this.onProvinceSelected();
  }

  get hasMinimumSearchQueryParams(): boolean {
    const value =
      this.disciplineSearch.provinces?.length > 0 ||
      this.disciplineSearch.professions?.length > 0 ||
      this.disciplineSearch.name?.trim().length > 0;
    return value;
  }

  ngOnDestroy(): void {
    this.onCloseDetails();
    this.subscription.unsubscribe();
  }
}
