import { createReducer, on } from '@ngrx/store';
import { setActivePath, showLoading, hideLoading } from './app.actions';
import { initialAppState } from './app.state';

export const appReducer = createReducer(
  initialAppState,
  on(setActivePath, (state, { activePath }) => ({
    ...state,
    activePath,
  })),
  on(showLoading, (state) => ({ ...state, loading: true })),
  on(hideLoading, (state) => ({ ...state, loading: false }))
);
