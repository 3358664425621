import { createAction, props } from '@ngrx/store';
import { Provider, ProviderSearch } from '../../models';

export const setProvidersSelectedBenefit = createAction(
  '[Provider state] Set Provider Selected Benefit',
  props<{ providersSelectedBenefit: string }>()
);

export const setProvidersSearchOptions = createAction(
  '[Provider state] Set Provider Search Options',
  props<{ providersSearchOptions: ProviderSearch }>()
);

export const setProvidersResultsDataByBenefit = createAction(
  '[Provider state] Set Providers Results Data',
  props<{ benefit: string; providersResultsData: Provider[] }>()
);

export const setProvidersResultsFailure = createAction(
  '[Provider] Load Providers Failure',
  props<{ error: any }>()
);

export const setSelectedProviderDetails = createAction(
  '[Provider state] Set Selected Details',
  props<{ selectedProviderDetails: Provider }>()
);

export const setHasMinimumSearchQueryParams = createAction(
  '[Provider state] Set Has Minimum Search Query Params',
  props<{ hasMinimumSearchQueryParams: boolean }>()
);
