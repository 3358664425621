import { Professional, ProfessionalSearch } from '../../models';

export interface ProfessionalsState {
  professionalsSearchOptions: ProfessionalSearch;
  professionalSelectedBenefit: string;
  professionalsResultsData: {
    [benefit: string]: Professional[];
  };
  selectedProfessionalDetails?: Professional;
  hasMinimumSearchQueryParams: boolean;
}

export const initialProfessionalsState: ProfessionalsState = {
  professionalsSearchOptions: new ProfessionalSearch(),
  professionalSelectedBenefit: '',
  professionalsResultsData: {},
  selectedProfessionalDetails: null,
  hasMinimumSearchQueryParams: false,
};
