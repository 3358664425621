import { Provider } from "@angular/core";
import { Professional } from "./professional";

export class DisciplineSearch {
  benefits: string[] | null = [];
  provinces: string[] | null = [];
  types: string[] | null;
  professions: string[] | null = [];
  regulatoryBodies: string[] | null;
  name: string | null;
  dateFrom: Date | null;
  dateTo: Date | null;
  benefitSearchGroups: BenefitSearchGroup[] | null;

  professional: Professional;
  provider: Provider;
  searchResult: SearchResult | null;
}

export class BenefitSearchGroup {
  benefit: string | null;
  professions: string[] | null;
  provinces: string[] | null;
}

export class SearchResult {
  events: any[];
}
