import { createSelector, createFeatureSelector } from '@ngrx/store';
import { ProfessionalsState } from './professionals.state';

export const selectProfessionalsState =
  createFeatureSelector<ProfessionalsState>('professionals');

export const selectProfessionalsSearchOptions = createSelector(
  selectProfessionalsState,
  (state) => state.professionalsSearchOptions
);
export const selectProfessionalSelectedBenefit = createSelector(
  selectProfessionalsState,
  (state) => state.professionalSelectedBenefit
);

export const selectProfessionalsResultsByBenefit = (benefit: string) =>
  createSelector(
    selectProfessionalsState,
    (state) => state.professionalsResultsData[benefit]
  );

export const selectProfessionalsResultsData = createSelector(
  selectProfessionalsState,
  (state) => state.professionalsResultsData
);

export const selectSelectedProfessionalDetails = createSelector(
  selectProfessionalsState,
  (state) => state.selectedProfessionalDetails
);

export const selectHasMinimumSearchQueryParams = createSelector(
  selectProfessionalsState,
  (state) => state.hasMinimumSearchQueryParams
);
