import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { Provider } from '../../../models';
import { environment } from '../../../../environments/environment';
import { selectProvidersSelectedBenefit } from '../../../state/providers/providers.selectors';
import { Router } from '@angular/router';

@Component({
  selector: 'provider-details-view',
  templateUrl: './provider-details-view.component.html',
  styleUrl: './provider-details-view.component.scss',
})
export class ProviderDetailsViewComponent {
  @Input() provider!: Provider;
  @Input() province!: string;

  private subscription = new Subscription();
  resolvedSelectedBenefit!: string;
  environment = environment;

  constructor(private store: Store, private router: Router) {}

  ngOnInit(): void {
    const benefitSub = this.store
      .select(selectProvidersSelectedBenefit)
      .subscribe((benefit) => {
        this.resolvedSelectedBenefit = benefit;
      });
    this.subscription.add(benefitSub);
  }

  navigateToDisciplines(provider: Provider) {
    if (!provider) return;

    const providerData = {
      name: provider.name,
      benefit: [this.resolvedSelectedBenefit],
      province: [this.province],
      provider,
    };

    this.router.navigate(['/disciplines'], {
      state: { disciplineSearch: providerData },
      queryParams: {
        ben: this.resolvedSelectedBenefit,
        prov: this.province,
      },
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
