import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectIsAuthenticated } from '../state/auth/auth.selectors';
import {
  loginFetched,
  loginSuccess,
  loginFailure,
  logout,
  restoreAuthState,
} from '../state/auth/auth.actions';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private store: Store) {}

  login(username: string, password: string): boolean {
    this.store.dispatch(loginFetched());

    const authToken = this.fakeAuthApi(username, password);

    if (authToken) {
      localStorage.setItem('authToken', authToken);
      this.store.dispatch(loginSuccess({ authToken }));
      return true;
    }

    this.store.dispatch(
      loginFailure({ error: 'Invalid username or password, please try again' })
    );

    return false;
  }

  private fakeAuthApi(username: string, password: string): string | null {
    if (username === 'user' && password === '1234') {
      return 'dummy-token';
    }
    return null;
  }

  logout(): void {
    localStorage.removeItem('authToken');
    this.store.dispatch(logout());
  }

  isAuthenticated$(): Observable<boolean> {
    return this.store.select(selectIsAuthenticated);
  }

  restoreAuthState(): void {
    const authToken = localStorage.getItem('authToken');
    const isAuthenticated = !!authToken;
    this.store.dispatch(restoreAuthState({ isAuthenticated, authToken }));
  }
}
