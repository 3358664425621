import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { catchError, map, Observable, tap, throwError } from 'rxjs';
import { Provider } from '../models/provider';
import { ProviderSearch } from '../models/providerSearch';
import { Professional } from '../models/professional';
import { ProfessionalSearch } from '../models/professionalSearch';
import { NotificationService } from './notification.service';
import { hideLoading } from '../state/app/app.actions';
import { Store } from '@ngrx/store';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class ProviderService {
  private url = environment.pvtUrl;

  constructor(
    private http: HttpClient,
    private notificationService: NotificationService,
    private store: Store
  ) {}

  private log(message: string) {
    console.log(message);
  }

  private handleError<T>(operation = 'operation') {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      this.notificationService.showNotification(
        'Error fetching data',
        'Close',
        'error',
        Infinity
      );
      this.store.dispatch(hideLoading());
      return throwError(
        () => new Error(`Operation ${operation} failed: ${error.message}`)
      );
    };
  }

  getAllProviders(): Observable<Provider[]> {
    const url = `${this.url}/providers/all`;
    return this.http.get<Provider[]>(url).pipe(
      tap((_) => this.log(`getAllProviders`)),
      catchError(this.handleError<Provider[]>(`getAllProviders`))
    );
  }

  getProviderSearch(): Observable<ProviderSearch> {
    const url = `${this.url}/providers/providerSearch`;
    return this.http.get<ProviderSearch>(url, httpOptions).pipe(
      map((response: ProviderSearch) =>
        this.temporalProcessBenefitsPharmacyFirst<ProviderSearch>(response)
      ),
      tap((_) => this.log(`getProviderSearch`)),
      catchError(this.handleError<ProviderSearch>(`getProviderSearch`))
    );
  }

  searchProviders(providerSearch: ProviderSearch): Observable<Provider[]> {
    const url = `${this.url}/providers/search`;
    return this.http
      .post<Provider[]>(
        url,
        this.cleanSearchParams<ProviderSearch>(providerSearch),
        httpOptions
      )
      .pipe(
        map((providers: Provider[]) =>
          this.addConcernsFlag<Provider>(providers)
        ),
        tap((_) => this.log(`searchProviders`)),
        catchError(this.handleError<Provider[]>(`searchProviders`))
      );
  }

  getAllProfessionals(): Observable<Professional[]> {
    const url = `${this.url}/professionals/all`;
    return this.http.get<Professional[]>(url).pipe(
      tap((_) => this.log(`getAllProfessionals`)),
      catchError(this.handleError<Professional[]>(`getAllProfessionals`))
    );
  }

  getProfessionalSearch(): Observable<ProfessionalSearch> {
    const url = `${this.url}/professionals/professionalSearch`;
    return this.http.get<ProfessionalSearch>(url, httpOptions).pipe(
      map((response: ProfessionalSearch) =>
        this.temporalProcessBenefitsPharmacyFirst<ProfessionalSearch>(response)
      ),
      tap((_) => this.log(`getProfessionalSearch`)),
      catchError(this.handleError<ProfessionalSearch>(`getProfessionalSearch`))
    );
  }

  searchProfessionals(
    professionalSearch: ProfessionalSearch
  ): Observable<Professional[]> {
    const url = `${this.url}/professionals/search`;
    return this.http
      .post<Professional[]>(
        url,
        this.cleanSearchParams<ProfessionalSearch>(professionalSearch),
        httpOptions
      )
      .pipe(
        map((professionals: Professional[]) =>
          this.addConcernsFlag<Professional>(professionals)
        ),
        tap((_) => this.log(`searchProfessionals`)),
        catchError(this.handleError<Professional[]>(`searchProfessionals`))
      );
  }

  searchPaginatedProfessionals(
    professionalSearch: ProfessionalSearch
  ): Observable<ProfessionalSearch> {
    const url = `${this.url}/professionals/search2`;
    return this.http
      .post<ProfessionalSearch>(
        url,
        this.cleanSearchParams<ProfessionalSearch>(professionalSearch),
        httpOptions
      )
      .pipe(
        map((professionalsResults: ProfessionalSearch) => {
          professionalsResults.pageResult.content =
            this.addConcernsFlag<Professional>(
              professionalsResults.pageResult.content
            );
          return professionalsResults;
        }),
        tap((_) => this.log(`searchProfessionals`)),
        catchError(this.handleError<ProfessionalSearch>(`searchProfessionals`))
      );
  }

  private temporalProcessBenefitsPharmacyFirst<
    T extends { benefits: string[] }
  >(searchOptions: T): T {
    if (searchOptions.benefits && Array.isArray(searchOptions.benefits)) {
      if (searchOptions.benefits.length === 1) {
        searchOptions.benefits.push('Dental', 'Vision');
      }
      if (searchOptions.benefits.length > 1) {
        searchOptions.benefits.sort((a, b) => {
          // Temp for demo, place pharmacy first, rest in Alphabetical order
          if (a === 'Pharmacy') return -1;
          if (b === 'Pharmacy') return 1;
          return a.localeCompare(b);
        });
      }
    }
    return searchOptions;
  }

  private cleanSearchParams<T>(params: T): T {
    const cleanParams = { ...params };
    Object.keys(cleanParams).forEach((key) => {
      if (cleanParams[key]?.length === 0) {
        cleanParams[key] = null;
      }
      if (cleanParams[key]?.length === 1 && cleanParams[key][0] == null) {
        cleanParams[key] = null;
      }
    });
    return cleanParams;
  }

  private addConcernsFlag<T extends { events?: any[] }>(results: T[]): T[] {
    if (!Array.isArray(results) || results.length == 0) {
      return [];
    }

    return results.map((result) => ({
      ...result,
      hasConcerns: result.events?.length > 0 || false,
      hasConcernsString: result.events?.length > 0 ? 'concerns' : '',
    }));
  }
}
