import { createAction, props } from '@ngrx/store';
import { Discipline, DisciplineSearch } from '../../models';

export const setDisciplineSelectedBenefit = createAction(
  '[Disciplines state] Set Disciplines Selected Benefit',
  props<{ disciplineSelectedBenefit: string }>()
);

export const setDisciplinesSearchOptions = createAction(
  '[Disciplines state] Set Disciplines Search Options',
  props<{ disciplinesSearchOptions: DisciplineSearch }>()
);

export const setDisciplinesResultsDataByBenefit = createAction(
  '[Disciplines state] Set Disciplines Results Data',
  props<{ benefit: string; disciplinesResults: Discipline[] }>()
);

export const setDisciplinesResultsFailure = createAction(
  '[Disciplines] Load Disciplines Failure',
  props<{ error: any }>()
);

export const setSelectedDisciplineDetails = createAction(
  '[Disciplines state] Set Selected Details',
  props<{ selectedDisciplineDetails: Discipline }>()
);

export const setHasMinimumSearchQueryParams = createAction(
  '[Disciplines state] Set Has Minimum Search Query Params',
  props<{ hasMinimumSearchQueryParams: boolean }>()
);
