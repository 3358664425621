import { PageResult } from "./pageResult";

export class ProviderSearch {
  id: number;
  name: string;
  accreditationNumber: string;
  address: string;
  benefits: string[] = [];
  provinces: string[] = [];
  professions: string[] = [];
  benefitSearchGroups: BenefitSearchGroup[] | null;

  pageNumber: number;
  pageSize: number;
  pageResult: PageResult;
}

export class BenefitSearchGroup {
  benefit: string | null;
  provinces: {
    province: string;
    professions: string[];
  }[];
}
