import { Provider, ProviderSearch } from '../../models';

export interface ProviderState {
  providersSearchOptions: ProviderSearch;
  providersSelectedBenefit: string;
  providersResultsData: {
    [benefit: string]: Provider[];
  };
  error: null;
  selectedProviderDetails?: Provider;
  hasMinimumSearchQueryParams: boolean;
}

export const initialProviderState: ProviderState = {
  providersSearchOptions: new ProviderSearch(),
  providersSelectedBenefit: '',
  providersResultsData: {},
  error: null,
  selectedProviderDetails: null,
  hasMinimumSearchQueryParams: false,
};
