import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule, DatePipe } from '@angular/common';
import { provideHttpClient } from '@angular/common/http';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { AppMaterialModule } from './app-material.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './pages/common/footer/footer.component';
import { NavBarComponent } from './pages/common/nav-bar/nav-bar.component';
import { LoadingComponent } from './pages/common/loading/loading.component';
import { SafeHtmlViewerComponent } from './pages/common/safe-html-viewer/safe-html-viewer.component';
import { LoginComponent } from './pages/login/login.component';
import { ProviderListComponent } from './pages/provider/provider-list/provider-list.component';
import { ProfessionalListComponent } from './pages/professional/professional-list/professional-list.component';
import { DisciplineListComponent } from './pages/discipline/discipline-list/discipline-list.component';
import { ProviderDetailsViewComponent } from './pages/provider/provider-details-view/provider-details-view.component';
import { ProfessionalDetailsViewComponent } from './pages/professional/professional-details-view/professional-details-view.component';
import { DisciplineDetailsViewComponent } from './pages/discipline/discipline-details-view/discipline-details-view.component';
import { appReducer } from './state/app/app.reducer';
import { authReducer } from './state/auth/auth.reducer';
import { providersReducer } from './state/providers/providers.reducer';
import { professionalsReducer } from './state/professionals/professionals.reducer';
import { disciplinesReducer } from './state/disciplines/disciplines.reducer';

import { environment } from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NavBarComponent,
    LoadingComponent,
    SafeHtmlViewerComponent,
    ProviderListComponent,
    ProviderDetailsViewComponent,
    ProfessionalListComponent,
    ProfessionalDetailsViewComponent,
    DisciplineListComponent,
    DisciplineDetailsViewComponent,
    FooterComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AppMaterialModule,
    CommonModule,
    FormsModule,
    StoreModule.forRoot({
      app: appReducer,
      auth: authReducer,
      providers: providersReducer,
      professionals: professionalsReducer,
      disciplines: disciplinesReducer,
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains the last 25 states
      logOnly: environment.production, // Set to true to disable actions logging in production
    }),
  ],
  providers: [
    provideAnimationsAsync(),
    provideHttpClient(),
    DatePipe,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { subscriptSizing: 'dynamic' },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
