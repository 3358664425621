<div class="provider-details-container" *ngIf="provider">
  <mat-card appearance="outlined">
    <mat-card-content class="card-content">
      <mat-tab-group class="details-tab-group">
        <mat-tab label="Details">
          <div class="inline mt-10">
            <h4>
              <strong>Status: {{ provider.status || "n/a" }} </strong>
            </h4>
          </div>
          <div class="search-disciplines">
            <button
              mat-raised-button
              (click)="navigateToDisciplines(provider)"
              matTooltip="Search for disciplines"
            >
              <mat-icon>search</mat-icon>
              Search for disciplines
            </button>
          </div>
          <p><strong>Type:</strong> {{ provider.type || "n/a" }}</p>

          <div
            class="inline"
            *ngIf="
              provider.address1 ||
              provider.address2 ||
              provider.address3 ||
              provider.address4
            "
          >
            <mat-icon class="mat-icon-small">house</mat-icon>
            <p>
              <strong>Address:</strong>
              {{ provider.address1 }}
              {{ provider.address2 }}
              {{ provider.address3 }}
              {{ provider.address4 }}
            </p>
          </div>

          <div class="inline">
            <mat-icon class="mat-icon-small">email</mat-icon>
            <p><strong>Email:</strong></p>
            <p>{{ provider.email || "n/a" }}</p>
          </div>

          <div class="inline">
            <mat-icon class="mat-icon-small">phone</mat-icon>
            <p><strong>Phone:</strong></p>
            <p>{{ provider.phone || "n/a" }}</p>
          </div>

          <div class="inline">
            <mat-icon class="mat-icon-small">fax</mat-icon>
            <p><strong>Fax:</strong></p>
            <p>{{ provider.fax || "n/a" }}</p>
          </div>

          <div class="inline">
            <mat-icon class="mat-icon-small">date_range</mat-icon>
            <p>
              <strong>Issued: </strong
              >{{ (provider.dateIssued | date : "MMMM d, y") || "n/a" }}
            </p>
            <p *ngIf="provider.dateClosed">
              -- <strong>Closed: </strong>
              {{ (provider.dateClosed | date : "MMMM d, y") || "n/a" }}
            </p>
          </div>

          <p>
            <strong>Acreditor #:</strong>
            {{ provider.accreditationNumber || "n/a" }}
          </p>
          <p>
            <strong>UID:</strong>
            {{ provider.uid || "n/a" }}
          </p>
          <p>
            <strong>Licence effective date:</strong>
            {{ (provider.licenceEffectiveDate | date : "MMMM d, y") || "n/a" }}
          </p>
          <p>
            <strong>Licence expiry date:</strong>
            {{ (provider.licenceExpiryDate | date : "MMMM d, y") || "n/a" }}
          </p>

          <div class="inline">
            <p>
              <strong>Regulatory Body: </strong
              >{{ provider.website.regulatoryBody || "n/a" }}
            </p>
            <a
              href="https://{{ provider.website.regulatoryBodyWebsite }}"
              matTooltip="https://{{ provider.website.regulatoryBodyWebsite }}"
              target="_blank"
              class="link-with-icon"
            >
              <mat-icon class="mat-icon-small">open_in_new</mat-icon>
            </a>
          </div>

          <div class="mt-10" *ngIf="provider.owner">
            <p><strong>Organization</strong></p>
            <div class="inline">
              <p *ngIf="provider.owner?.name">
                Name: {{ provider.owner?.name }}
              </p>
              <a
                href="{{ provider.url }}"
                matTooltip="{{ provider.url }}"
                target="_blank"
                class="link-with-icon"
              >
                <mat-icon class="mat-icon-small">open_in_new</mat-icon>
              </a>
            </div>
            <p *ngIf="provider.owner?.type">Type: {{ provider.owner?.type }}</p>

            <div *ngIf="provider.owner?.directors.length > 0" class="mt-10">
              <p><strong>Director(s):</strong></p>
              <ul class="horizontal-professional-list">
                <li *ngFor="let professional of provider.owner?.directors">
                  <a
                    href="{{ environment.baseUrl }}/professionals?ben={{
                      resolvedSelectedBenefit
                    }}&prov={{ province }}&n={{ professional.name }}&id={{
                      professional.id
                    }}"
                    matTooltip="{{ environment.baseUrl }}/professionals?ben={{
                      resolvedSelectedBenefit
                    }}&prov={{ province }}&n={{ professional.name }}&id={{
                      professional.id
                    }}"
                  >
                    <p>
                      <strong>{{ professional.name }}</strong>
                    </p>
                  </a>

                  <p *ngIf="professional.profession">
                    Profession: {{ professional.profession }}
                  </p>
                  <p *ngIf="professional.position">
                    Position: {{ professional.position }}
                  </p>
                </li>
              </ul>
            </div>
          </div>

          <div *ngIf="provider.staff.length > 0" class="mt-10">
            <p><strong>Staff:</strong></p>
            <ul class="horizontal-professional-list">
              <li *ngFor="let professional of provider.staff">
                <a
                  href="{{ environment.baseUrl }}/professionals?ben={{
                    resolvedSelectedBenefit
                  }}&prov={{ province }}&n={{ professional.name }}&id={{
                    professional.id
                  }}"
                  matTooltip="{{ environment.baseUrl }}/professionals?ben={{
                    resolvedSelectedBenefit
                  }}&prov={{ province }}&n={{ professional.name }}&id={{
                    professional.id
                  }}"
                >
                  <p>
                    <strong>{{ professional.name }}</strong>
                  </p>
                </a>

                <p *ngIf="professional.position">
                  Position: {{ professional.position }}
                </p>
                <p *ngIf="professional.profession">
                  Profession: {{ professional.profession }}
                </p>
              </li>
            </ul>
          </div>
          <div class="last-updated">
            <p>
              Last updated:
              {{ (provider.lastUpdatedDate | date : "MMMM d, y") || "n/a" }}
            </p>
          </div>
        </mat-tab>

        <mat-tab *ngIf="provider.hasConcerns">
          <ng-template mat-tab-label>
            <mat-icon>warning</mat-icon> Concerns
          </ng-template>
          <div *ngFor="let event of provider.events">
            <div *ngFor="let document of event.documents">
              <app-safe-html-viewer
                [rawHtml]="document.content"
              ></app-safe-html-viewer>

              <div class="last-updated">
                <p>
                  Last updated:
                  {{ document.lastUpdatedDate | date : "MMMM d, y" }}
                </p>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
</div>
