import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { AuthState } from '../../state/auth/auth.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent {
  email: string = '';
  password: string = '';
  errorMessage$;

  constructor(
    private authService: AuthService,
    private router: Router,
    private store: Store<{ auth: AuthState }>
  ) {
    this.errorMessage$ = this.store.select((state) => state.auth.errorMessage);
  }

  onLogin(): void {
    const isSuccess = this.authService.login(this.email, this.password);
    if (isSuccess) {
      this.router.navigate(['/providers']);
    }
  }
}
