<div class="details-view-container" *ngIf="professional">
  <mat-card appearance="outlined">
    <mat-card-content class="card-content">
      <mat-tab-group class="details-tab-group">
        <mat-tab label="Details">
          <div class="inline mt-10">
            <h4>
              <strong>Status: {{ professional.status || "N/A" }}</strong>
            </h4>
          </div>
          <div class="search-disciplines">
            <button
              mat-raised-button
              (click)="navigateToDisciplines(professional)"
              matTooltip="Search for disciplines"
            >
              <mat-icon>search</mat-icon>
              Search for disciplines
            </button>
          </div>
          <p>
            <strong>UID:</strong>
            {{ professional.uid || "N/A" }}
          </p>
          <p>
            <strong>Preferred name:</strong>
            {{ professional.preferredName || "N/A" }}
          </p>
          <p>
            <strong>Previous name:</strong>
            {{ professional.previousNames || "N/A" }}
          </p>
          <p>
            <strong>Profession:</strong>
            {{ professional.profession || "N/A" }}
          </p>
          <p>
            <strong>Gender:</strong>
            {{ professional.gender || "N/A" }}
          </p>
          <p>
            <strong>Registration number:</strong>
            {{ professional.registrationNumber || "N/A" }}
          </p>
          <p>
            <strong>Registration date:</strong>
            {{ professional.registrationDate || "N/A" }}
          </p>
          <p>
            <strong>Authorizations:</strong>
            {{ professional.authorizations || "N/A" }}
          </p>
          <p>
            <strong>Concerns / Disciplines:</strong>
            {{ professional.disciplines || "N/A" }}
          </p>
          <p>
            <strong>Licence type:</strong>
            {{ professional.licenceType || "N/A" }}
          </p>
          <p>
            <strong>Licence expiry:</strong>
            {{ professional.licenceExpiryDate || "N/A" }}
          </p>
          <p>
            <strong>Licence conditions:</strong>
            {{ professional.licenceConditions || "N/A" }}
          </p>

          <div
            class="mt-10"
            *ngIf="
              professional?.workplaces?.length > 0 &&
              professional?.workplaces[0]?.provider
            "
          >
            <p><strong>Workplace:</strong></p>
            <ul class="horizontal-professional-list">
              <li *ngFor="let workplace of professional.workplaces">
                <a
                  href="{{ environment.baseUrl }}/providers?ben={{
                    resolvedSelectedBenefit
                  }}&prov={{ province }}&n={{ workplace.provider?.name }}&id={{
                    workplace.provider?.id
                  }}"
                  matTooltip="{{ environment.baseUrl }}/providers?ben={{
                    resolvedSelectedBenefit
                  }}&prov={{ province }}&n={{ workplace.provider?.name }}&id={{
                    workplace.provider?.id
                  }}"
                >
                  <p>
                    <strong>
                      {{ workplace.provider?.name }}
                    </strong>
                  </p>
                </a>
                <p>
                  {{ workplace.provider.address1 }}
                  {{ workplace.provider.address2 }}
                </p>
                <p>{{ workplace.provider.address3 }}</p>
                <p>{{ workplace.provider.address4 }}</p>
                <p *ngIf="workplace.position">
                  Position: {{ workplace.position }}
                </p>
              </li>
            </ul>
          </div>

          <div *ngIf="professional.directorAts?.length > 0">
            <p><strong>Other workplaces:</strong></p>
            <ul class="horizontal-professional-list">
              <li *ngFor="let workplace of professional.directorAts">
                <div class="inline">
                  <a
                    href="{{ environment.baseUrl }}/providers?ben={{
                      resolvedSelectedBenefit
                    }}&prov={{ province }}&n={{ workplace.provider.name }}&id={{
                      workplace.provider.id
                    }}"
                    matTooltip="{{ environment.baseUrl }}/providers?ben={{
                      resolvedSelectedBenefit
                    }}&prov={{ province }}&n={{ workplace.provider.name }}&id={{
                      workplace.provider.id
                    }}"
                  >
                    <p>
                      <strong>
                        {{ workplace.provider.name }}
                      </strong>
                    </p>
                  </a>
                  <a
                    href="{{ workplace.provider.url }}"
                    matTooltip="{{ workplace.provider.url }}"
                  >
                    <mat-icon class="mat-icon-small">open_in_new</mat-icon>
                  </a>
                </div>
                <p *ngIf="workplace.position">
                  Position: {{ workplace.position }}
                </p>
              </li>
            </ul>
          </div>
          <div class="last-updated">
            <p>
              Last updated:
              {{ (professional.lastUpdatedDate | date : "MMMM d, y") || "n/a" }}
            </p>
          </div>
        </mat-tab>

        <mat-tab *ngIf="professional.events?.length > 0">
          <ng-template mat-tab-label *ngIf="professional.events?.length > 0">
            <mat-icon>warning</mat-icon> Concerns
          </ng-template>
          <div *ngFor="let event of professional.events">
            <div *ngFor="let document of event.documents">
              <app-safe-html-viewer
                [rawHtml]="document.content"
              ></app-safe-html-viewer>

              <div class="last-updated">
                <p>
                  Last updated:
                  {{ document.lastUpdatedDate | date : "MMMM d, y" }}
                </p>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
</div>
