import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError, tap, map } from 'rxjs';

import { environment } from '../../environments/environment';
import { NotificationService } from './notification.service';
import { Discipline } from '../models/discipline';
import { DisciplineSearch } from '../models/disciplineSearch';
import { hideLoading } from '../state/app/app.actions';
import { Store } from '@ngrx/store';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class DisciplineService {
  private url = environment.pvtUrl;

  constructor(
    private http: HttpClient,
    private notificationService: NotificationService,
    private store: Store
  ) {}

  private log(message: string) {
    console.log(message);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      this.notificationService.showNotification(
        'Error fetching data',
        'Close',
        'error',
        Infinity
      );
      this.store.dispatch(hideLoading());
      return throwError(
        () => new Error(`Operation ${operation} failed: ${error.message}`)
      );
    };
  }

  getAllDisciplines(): Observable<Discipline[]> {
    const url = `${this.url}/disciplines/all`;
    return this.http.get<Discipline[]>(url).pipe(
      tap((_) => this.log(`getAllDisciplines`)),
      catchError(this.handleError<Discipline[]>(`getDisciplines`))
    );
  }

  searchDisciplines(
    disciplineSearch: DisciplineSearch
  ): Observable<Discipline[]> {
    const url = `${this.url}/disciplines/search`;
    return this.http
      .post<Discipline[]>(
        url,
        this.cleanSearchParams(disciplineSearch),
        httpOptions
      )
      .pipe(
        map((disciplines: Discipline[]) =>
          this.addConcernsFlag<Discipline>(disciplines)
        ),
        tap((_) => this.log(`searchDisciplines`)),
        catchError(this.handleError<Discipline[]>(`searchDisciplines`))
      );
  }

  searchDisciplines2(
    disciplinesSearch: DisciplineSearch
  ): Observable<DisciplineSearch> {
    const url = `${this.url}/disciplines/search2`;
    return this.http
      .post<DisciplineSearch>(url, disciplinesSearch, httpOptions)
      .pipe(
        tap((_) => this.log(`searchDisciplines2`)),
        catchError(this.handleError<DisciplineSearch>(`searchDisciplines2`))
      );
  }

  getDisciplineSearch(): Observable<DisciplineSearch> {
    const url = `${this.url}/disciplines/disciplineSearch`;
    return this.http.get<DisciplineSearch>(url, httpOptions).pipe(
      map((response: DisciplineSearch) =>
        this.temporalProcessBenefits(response)
      ),
      tap((_) => this.log(`getDisciplineSearch`)),
      catchError(this.handleError<DisciplineSearch>(`getDisciplineSearch`))
    );
  }

  private temporalProcessBenefits(
    disciplineSearch: DisciplineSearch
  ): DisciplineSearch {
    if (disciplineSearch.benefits && Array.isArray(disciplineSearch.benefits)) {
      if (disciplineSearch.benefits.length === 1) {
        disciplineSearch.benefits.push('Dental', 'Vision');
      }
      if (disciplineSearch.benefits.length > 1) {
        disciplineSearch.benefits.sort((a, b) => {
          // Temp for demo, place pharmacy first, rest in Alphabetical order
          if (a === 'Pharmacy') return -1;
          if (b === 'Pharmacy') return 1;
          return a.localeCompare(b);
        });
      }
    }
    return disciplineSearch;
  }

  private cleanSearchParams(params: DisciplineSearch): DisciplineSearch {
    const cleanParams = { ...params };
    Object.keys(cleanParams).forEach((key) => {
      if (cleanParams[key]?.length === 0) {
        cleanParams[key] = null;
      }
      if (cleanParams[key]?.length === 1 && cleanParams[key][0] == null) {
        cleanParams[key] = null;
      }
    });
    return cleanParams;
  }

  private addConcernsFlag<T extends { documents?: any[] }>(results: T[]): T[] {
    if (!Array.isArray(results) || results.length == 0) {
      return [];
    }

    return results.map((result) => ({
      ...result,
      hasConcerns: result.documents?.length > 0 || false,
      hasConcernsString: result.documents?.length > 0 ? 'concerns' : '',
    }));
  }
}
