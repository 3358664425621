import { createReducer, on } from '@ngrx/store';
import {
  setProfessionalsSearchOptions,
  setProfessionalSelectedBenefit,
  setProfessionalsResultsByBenefit,
  setSelectedProfessionalDetails,
  setHasMinimumSearchQueryParams,
} from './professionals.actions';
import { initialProfessionalsState } from './professionals.state';

export const professionalsReducer = createReducer(
  initialProfessionalsState,
  on(
    setProfessionalsSearchOptions,
    (state, { professionalsSearchOptions }) => ({
      ...state,
      professionalsSearchOptions,
    })
  ),
  on(
    setProfessionalSelectedBenefit,
    (state, { professionalSelectedBenefit }) => ({
      ...state,
      professionalSelectedBenefit,
    })
  ),
  on(
    setProfessionalsResultsByBenefit,
    (state, { benefit, professionalsResults }) => ({
      ...state,
      professionalsResultsData: {
        ...state.professionalsResultsData,
        [benefit]: professionalsResults, // Add or update the results for the given benefit
      },
    })
  ),
  on(
    setSelectedProfessionalDetails,
    (state, { selectedProfessionalDetails }) => ({
      ...state,
      selectedProfessionalDetails,
    })
  ),
  on(
    setHasMinimumSearchQueryParams,
    (state, { hasMinimumSearchQueryParams }) => ({
      ...state,
      hasMinimumSearchQueryParams,
    })
  )
);
