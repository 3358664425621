<div class="details-view-container" *ngIf="discipline">
  <mat-card appearance="outlined">
    <mat-card-content class="card-content">
      <mat-tab-group class="details-tab-group">
        <mat-tab label="Details">
          <div class="search-professional">
            <a
              href="{{ environment.baseUrl }}/professionals?ben={{
                selectedBenefit$ | async
              }}&prov={{ province }}&n={{ discipline.name }}&id={{
                discipline.id
              }}"
              matTooltip="{{ environment.baseUrl }}/professionals?ben={{
                selectedBenefit$ | async
              }}&prov={{ province }}&n={{ discipline.name }}&id={{
                discipline.id
              }}"
              mat-raised-button
            >
              <mat-icon>search</mat-icon>
              Search for professional
            </a>
          </div>
          <div class="inline">
            <p>
              <strong>Discipline:</strong>
            </p>
            <a
              *ngIf="discipline.link"
              href="{{ discipline.link }}"
              matTooltip="{{ discipline.link }}"
              target="_blank"
              class="link-with-icon"
            >
              <mat-icon class="mat-icon-small">open_in_new</mat-icon>
            </a>
          </div>
          <p>
            <strong>ID:</strong>
            {{ discipline.id || "N/A" }}
          </p>
          <div class="inline">
            <p>
              <strong>Employee Name:</strong>
              {{ discipline.name || "N/A" }}
            </p>
          </div>
          <div class="inline">
            <p>
              <strong>Location:</strong>
              {{ discipline.location || "N/A" }}
            </p>
          </div>

          <div class="inline">
            <mat-icon class="mat-icon-small">date_range</mat-icon>
            <p>
              <strong>Date: </strong
              >{{ (discipline.date | date : "MMMM d, y") || "n/a" }}
            </p>
          </div>
          <p>
            <strong>Type:</strong>
            {{ discipline.type || "N/A" }}
          </p>
          <p>
            <strong>Profession:</strong>
            {{ discipline.website.profession || "N/A" }}
          </p>
          <p>
            <strong>Province:</strong>
            {{ discipline.website.province || "N/A" }}
          </p>
          <p>
            <strong>Benefit:</strong>
            {{ discipline.website.benefit || "N/A" }}
          </p>
          <div class="last-updated">
            <p>
              Last updated:
              {{ (discipline.lastUpdatedDate | date : "MMMM d, y") || "n/a" }}
            </p>
          </div>
        </mat-tab>

        <mat-tab *ngIf="discipline.documents?.length > 0">
          <ng-template mat-tab-label *ngIf="discipline.documents?.length > 0">
            <mat-icon>warning</mat-icon> Concerns
          </ng-template>
          <div *ngFor="let document of discipline.documents">
            <app-safe-html-viewer
              [rawHtml]="document.content"
            ></app-safe-html-viewer>

            <div class="last-updated">
              <p>
                Last updated:
                {{ document.lastUpdatedDate | date : "MMMM d, y" }}
              </p>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
</div>
