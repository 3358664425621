import { Component } from '@angular/core';
import { Store } from '@ngrx/store';

import { AuthService } from './services/auth.service';
import { ProviderService } from './services/provider.service';
import { DisciplineService } from './services/discipline.service';
import { setProvidersSearchOptions } from './state/providers/providers.actions';
import { setProfessionalsSearchOptions } from './state/professionals/professionals.actions';
import { setDisciplinesSearchOptions } from './state/disciplines/disciplines.actions';
import { ProviderSearch } from './models/providerSearch';
import { DisciplineSearch, ProfessionalSearch } from './models';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'PVT';
  providerSearchParamLoaded: boolean = false;
  professionalSearchParamLoaded: boolean = false;
  disciplineSearchParamLoaded: boolean = false;
  constructor(
    private authService: AuthService,
    private providerService: ProviderService,
    private disciplineService: DisciplineService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.authService.restoreAuthState();
    this.loadProvidersSearchOptions();
    this.loadProfessionalsSearchOptions();
    this.loadDisciplinesSearchOptions();
  }

  private loadProvidersSearchOptions() {
    if (!this.providerSearchParamLoaded) {
      this.providerService
        .getProviderSearch()
        .subscribe((providerSearchResponse) => {
          const providerCleanOptions = this.cleanSearchParams<ProviderSearch>(
            providerSearchResponse
          );
          this.store.dispatch(
            setProvidersSearchOptions({
              providersSearchOptions: providerCleanOptions,
            })
          );
          this.providerSearchParamLoaded = true;
        });
    }
  }

  private loadProfessionalsSearchOptions() {
    if (!this.professionalSearchParamLoaded) {
      this.providerService
        .getProfessionalSearch()
        .subscribe((professionalSearchResponse) => {
          const professionalCleanOptions =
            this.cleanSearchParams<ProfessionalSearch>(
              professionalSearchResponse
            );
          this.store.dispatch(
            setProfessionalsSearchOptions({
              professionalsSearchOptions: professionalCleanOptions,
            })
          );
          this.professionalSearchParamLoaded = true;
        });
    }
  }

  private loadDisciplinesSearchOptions() {
    if (!this.disciplineSearchParamLoaded) {
      this.disciplineService
        .getDisciplineSearch()
        .subscribe((disciplineSearchResponse) => {
          const cleanOptions = this.cleanSearchParams<DisciplineSearch>(
            disciplineSearchResponse
          );
          this.store.dispatch(
            setDisciplinesSearchOptions({
              disciplinesSearchOptions: cleanOptions,
            })
          );
          this.disciplineSearchParamLoaded = true;
        });
    }
  }

  private cleanSearchParams<T>(params: T): T {
    const cleanParams = { ...params };
    Object.keys(cleanParams).forEach((key) => {
      const value = cleanParams[key];
      if (value === '') {
        cleanParams[key] = null;
      } else if (Array.isArray(value)) {
        cleanParams[key] = value.filter((item) => item !== '');
      }
    });
    return cleanParams;
  }
}
