import { createSelector, createFeatureSelector } from '@ngrx/store';
import { AppState } from './app.state';

export const selectAppState = createFeatureSelector<AppState>('app');

export const selectActivePath = createSelector(
  selectAppState,
  (state) => state.activePath
);

export const selectLoading = createSelector(
  selectAppState,
  (state) => state.loading
);
