import { createAction, props } from '@ngrx/store';

export const loginFetched = createAction('[Auth] Login Fetched');

export const loginSuccess = createAction(
  '[Auth] Login Success',
  props<{ authToken: string }>()
);

export const loginFailure = createAction(
  '[Auth] Login Failure',
  props<{ error: string }>()
);

export const logout = createAction('[Auth] Logout');

export const restoreAuthState = createAction(
  '[Auth] Restore Auth State',
  props<{ isAuthenticated: boolean; authToken: string | null }>()
);
