<mat-toolbar color="primary">
  <div class="responsive-container content">
    <div class="navigation">
      <div class="navbar-logo" *ngIf="isAuthenticated$ | async">
        <img src="assets/images/pvt_logo_white.png" alt="Logo" />
      </div>
      <div class="nav-links" *ngIf="isAuthenticated$ | async">
        <button
          *ngFor="let link of navLinks"
          class="nav-link"
          [class.active]="currentPath === link.path"
          (click)="navigate(link.path)"
          type="button"
        >
          <p>{{ link.label }}</p>
        </button>
      </div>
    </div>

    <button
      mat-icon-button
      [matMenuTriggerFor]="menu"
      class="login-button"
      *ngIf="isAuthenticated$ | async"
    >
      <mat-icon>account_circle</mat-icon>
    </button>

    <mat-menu #menu="matMenu">
      <button mat-menu-item>
        <mat-icon>admin_panel_settings</mat-icon>
        <span>My Profile</span>
      </button>
      <button mat-menu-item>
        <mat-icon>card_membership</mat-icon>
        <span>Subscription</span>
      </button>
      <button mat-menu-item (click)="onLogout()">
        <mat-icon>logout</mat-icon>
        <span>Logout</span>
      </button>
    </mat-menu>
  </div>
</mat-toolbar>
