import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export const authGuard: CanActivateFn = (
  route,
  state
): Observable<boolean | UrlTree> => {
  const router = inject(Router);
  const authService = inject(AuthService);

  return authService.isAuthenticated$().pipe(
    map((isAuthenticated) => {
      const isLoginPage = state.url === '/login';
      if (isAuthenticated) {
        return !isLoginPage ? true : router.createUrlTree(['/providers']);
      }
      return !isLoginPage ? router.createUrlTree(['/login']) : true;
    })
  );
};
