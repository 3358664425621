import { createReducer, on } from '@ngrx/store';
import {
  setDisciplinesSearchOptions,
  setDisciplineSelectedBenefit,
  setDisciplinesResultsDataByBenefit,
  setDisciplinesResultsFailure,
  setSelectedDisciplineDetails,
  setHasMinimumSearchQueryParams,
} from './disciplines.actions';
import { initialDisciplinesState } from './disciplines.state';

export const disciplinesReducer = createReducer(
  initialDisciplinesState,
  on(setDisciplinesSearchOptions, (state, { disciplinesSearchOptions }) => ({
    ...state,
    disciplinesSearchOptions,
  })),
  on(setDisciplineSelectedBenefit, (state, { disciplineSelectedBenefit }) => ({
    ...state,
    disciplineSelectedBenefit,
  })),
  on(
    setDisciplinesResultsDataByBenefit,
    (state, { benefit, disciplinesResults }) => ({
      ...state,
      disciplinesResultsData: {
        ...state.disciplinesResultsData,
        [benefit]: disciplinesResults, // Add or update the results for the given benefit
      },
    })
  ),
  on(setDisciplinesResultsFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(setSelectedDisciplineDetails, (state, { selectedDisciplineDetails }) => ({
    ...state,
    selectedDisciplineDetails,
  })),
  on(
    setHasMinimumSearchQueryParams,
    (state, { hasMinimumSearchQueryParams }) => ({
      ...state,
      hasMinimumSearchQueryParams,
    })
  )
);
