import { Discipline, DisciplineSearch } from '../../models';

export interface DisciplinesState {
  disciplinesSearchOptions: DisciplineSearch;
  disciplineSelectedBenefit: string;
  disciplinesResultsData: {
    [benefit: string]: Discipline[];
  };
  error: null;
  selectedDisciplineDetails?: Discipline;
  hasMinimumSearchQueryParams: boolean;
}

export const initialDisciplinesState: DisciplinesState = {
  disciplinesSearchOptions: new DisciplineSearch(),
  disciplineSelectedBenefit: '',
  disciplinesResultsData: {},
  error: null,
  selectedDisciplineDetails: null,
  hasMinimumSearchQueryParams: false,
};
