<div class="professional-list-page">
  <header>
    <mat-card appearance="outlined" *ngIf="professionalsSearchParam">
      <mat-card-header>
        <mat-tab-group
          class="benefits-tabs"
          (selectedIndexChange)="onBenefitTabIndexChange($event)"
          [(selectedIndex)]="selectedBenefitTabIndex"
          (click)="resetPreloadParams()"
        >
          <mat-tab
            class="benefit-tab"
            [label]="benefit"
            *ngFor="let benefit of professionalsSearchParam.benefits"
          >
            <ng-template mat-tab-label>
              <mat-icon>folder</mat-icon>{{ benefit }}
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </mat-card-header>
      <mat-card-content>
        <div class="search-grid-container">
          <mat-form-field appearance="outline">
            <mat-label>Province</mat-label>
            <mat-select
              (click)="onUserInteractChangeProvice()"
              (selectionChange)="onProvinceSelected($event.value)"
              [(value)]="professionalSearch.provinces[0]"
              [disabled]="['Vision', 'Dental'].includes(selectedBenefit)"
            >
              <mat-option
                *ngFor="let province of filteredProvinces"
                [value]="province"
                >{{ province }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Profession</mat-label>
            <mat-select
              [(value)]="professionalSearch.professions[0]"
              [disabled]="
                !professionalSearch.provinces[0] ||
                ['Vision', 'Dental'].includes(selectedBenefit)
              "
            >
              <mat-option [value]="null">(All)</mat-option>
              <mat-option
                *ngFor="let profession of filteredProfessions"
                [value]="profession"
              >
                {{ profession }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" class="provider-name">
            <mat-label>Name</mat-label>
            <input
              matInput
              [(ngModel)]="professionalSearch.name"
              (keydown.enter)="search()"
              [disabled]="['Vision', 'Dental'].includes(selectedBenefit)"
            />
          </mat-form-field>
          <mat-card-actions>
            <button
              mat-icon-button
              class="search-button"
              (click)="search()"
              [disabled]="
                !(professionalSearch.provinces?.length > 0) ||
                ['Vision', 'Dental'].includes(selectedBenefit)
              "
            >
              <mat-icon>search</mat-icon>
            </button>
            <button mat-button class="underlined-button" (click)="clear()">
              Clear search
            </button>
          </mat-card-actions>
        </div>
        <div class="paginator-container">
          <p><strong>Results:</strong></p>
          <mat-paginator
            #paginator
            [pageSizeOptions]="[20, 50, 100]"
            aria-label="Select page of professionals"
            (page)="searchPaginatedProfessionals()"
            [disabled]="
              !(professionalSearch.provinces?.length > 0) ||
              this.paginator.length == 0 ||
              ['Vision', 'Dental'].includes(selectedBenefit)
            "
          ></mat-paginator>
          <div class="show-concerns-toggle">
            <mat-slide-toggle
              [(ngModel)]="showConcernsOnly"
              (change)="updateResutsByConcernsFilter()"
            >
              Show Concerns Only
            </mat-slide-toggle>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </header>
  <main>
    <section
      class="results-list"
      [ngClass]="{
        expanded: !selectedProfessional
      }"
    >
      <mat-form-field
        class="filter-field"
        appearance="outline"
        *ngIf="matDataSource.data.length > 0"
      >
        <input
          #input
          matInput
          (keyup)="applyFilter($event)"
          placeholder="Filter the results"
        />
      </mat-form-field>

      <div class="table-container">
        <!-- No Results -->
        <h2 class="message" *ngIf="matDataSource.data.length == 0 && !loading">
          {{ resultsMessage }}
        </h2>

        <!-- Results -->
        <table
          *ngIf="matDataSource.data.length > 0"
          mat-table
          [dataSource]="matDataSource"
          matSort
          class="results-table"
          tabindex="0"
          (keydown)="onKeydown($event)"
        >
          <ng-container matColumnDef="results">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Primary details
            </th>
            <td
              mat-cell
              *matCellDef="let professional"
              [ngClass]="{
                'selected-row-indicator':
                  professional.id === selectedProfessional?.id
              }"
            >
              <div class="details-row-box">
                <div
                  class="box"
                  [ngClass]="{
                    'primary-data': selectedProfessional
                  }"
                >
                  <p>
                    <strong>{{ professional.name }}</strong>
                  </p>
                </div>

                <div class="box" *ngIf="!selectedProfessional">
                  <div>
                    <p>
                      Workplace:
                      {{ professional.workplaces[0]?.provider?.name }}
                    </p>
                    <p *ngIf="professional.workplaces.length > 1">
                      and {{ professional.workplaces.length - 1 }} more records
                    </p>
                  </div>
                </div>

                <div class="box" *ngIf="!selectedProfessional">
                  <p>
                    Concerns:
                    {{ professional.hasConcerns ? "Yes" : "No" }}
                  </p>
                  <p>
                    Status:
                    {{ professional.status }}
                  </p>
                </div>

                <div class="box" *ngIf="!selectedProfessional">
                  <p>
                    Registration number: {{ professional.registrationNumber }}
                  </p>
                  <p>{{ professional.authorizations }}</p>
                </div>

                <div class="box tr" *ngIf="selectedProfessional">
                  <p class="mt-10">
                    Concerns:
                    {{ professional.hasConcerns ? "Yes" : "No" }}
                  </p>
                </div>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            *matRowDef="
              let professional;
              columns: displayedColumns;
              let i = index
            "
            (click)="openDetails(professional, i)"
          ></tr>
        </table>
      </div>
    </section>

    <mat-card
      appearance="outlined"
      class="details-container"
      *ngIf="selectedProfessional"
    >
      <div class="details-title">
        <div class="inline">
          <h3>{{ selectedProfessional.name }}</h3>
          <a
            href="{{ selectedProfessional.url }}"
            matTooltip="{{ selectedProfessional.url }}"
            target="_blank"
            class="link-with-icon"
          >
            <mat-icon class="mat-icon-small">open_in_new</mat-icon>
          </a>
        </div>
        <button mat-icon-button aria-label="Close" (click)="onCloseDetails()">
          <mat-icon>close</mat-icon>
        </button>
      </div>

      <mat-card-content class="details-card-content">
        <app-professional-details-view
          [professional]="selectedProfessional"
          [province]="professionalSearch.provinces[0]"
        ></app-professional-details-view>
      </mat-card-content>
    </mat-card>
  </main>
</div>
