<div class="login-container">
  <div class="image">
    <div class="logo">
      <img src="assets/images/login_background.png" alt="Logo" />
    </div>
  </div>
  <div class="form">
    <mat-card class="login-card">
      <mat-card-title>Login</mat-card-title>
      <mat-card-content>
        <form
          (ngSubmit)="onLogin()"
          #loginForm="ngForm"
          class="form"
          autocomplete="on"
        >
          <mat-form-field appearance="outline" class="input-field">
            <mat-label>Email</mat-label>
            <input
              matInput
              type="email"
              [(ngModel)]="email"
              name="email"
              autocomplete="email"
              required
            />
          </mat-form-field>
          <mat-form-field appearance="outline" class="input-field">
            <mat-label>Password</mat-label>
            <input
              matInput
              type="password"
              [(ngModel)]="password"
              name="password"
              autocomplete="current-password"
              required
            />
          </mat-form-field>
          <button
            mat-raised-button
            color="primary"
            type="submit"
            [disabled]="!loginForm.valid"
          >
            Login
          </button>
          <p
            *ngIf="errorMessage$ | async as errorMessage"
            class="error-message"
          >
            {{ errorMessage }}
          </p>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>
