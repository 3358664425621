import { createReducer, on } from '@ngrx/store';
import { initialAuthState } from './auth.state';
import {
  loginFetched,
  loginSuccess,
  loginFailure,
  logout,
  restoreAuthState,
} from './auth.actions';

export const authReducer = createReducer(
  initialAuthState,

  // When login is successful, update the state
  on(loginFetched, (state) => ({
    ...state,
    errorMessage: null, // Clear error message on successful login
  })),

  // When login is successful, update the state
  on(loginSuccess, (state, { authToken }) => ({
    ...state,
    isAuthenticated: true,
    authToken,
    errorMessage: null, // Clear error message on successful login
  })),

  on(loginFailure, (state, { error }) => ({
    ...state,
    isAuthenticated: false,
    authToken: null,
    errorMessage: error, // Set the error message on failure
  })),

  // When logout is called, reset the state
  on(logout, (state) => ({
    ...state,
    isAuthenticated: false,
    authToken: null,
  })),

  // Restore the authentication state (e.g., from localStorage on app load)
  on(restoreAuthState, (state, { isAuthenticated, authToken }) => ({
    ...state,
    isAuthenticated,
    authToken,
  }))
);
