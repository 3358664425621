import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, switchMap, combineLatest } from 'rxjs';
import { Store } from '@ngrx/store';

import { Professional } from '../../../models/professional';
import { ProfessionalSearch } from '../../../models/professionalSearch';
import { ProviderService } from '../../../services/provider.service';
import { NotificationService } from './../../../services/notification.service';
import {
  setProfessionalSelectedBenefit,
  setProfessionalsResultsByBenefit,
  setSelectedProfessionalDetails,
} from '../../../state/professionals/professionals.actions';
import {
  selectProfessionalSelectedBenefit,
  selectProfessionalsResultsByBenefit,
  selectSelectedProfessionalDetails,
  selectProfessionalsSearchOptions,
} from '../../../state/professionals/professionals.selectors';
import { hideLoading, showLoading } from '../../../state/app/app.actions';

@Component({
  selector: 'app-professional-list',
  templateUrl: './professional-list.component.html',
  styleUrl: './professional-list.component.scss',
})
export class ProfessionalListComponent implements OnInit {
  private subscription = new Subscription();
  private userInteracted = false;
  @ViewChild('paginator') paginator: MatPaginator;
  matDataSource: MatTableDataSource<Professional> = new MatTableDataSource();
  professionalSearch: ProfessionalSearch = new ProfessionalSearch();
  professionalsSearchParam: ProfessionalSearch = new ProfessionalSearch();

  openDetailsByID?: number = null;
  selectedProfessional?: Professional = null;
  loading: boolean = false;
  professionals: Professional[] = [];
  selectedBenefit?: string = '';
  selectedBenefitTabIndex: number = 0;

  benefitSearchGroupsSelected: {
    province: string;
    professions: string[];
  }[];
  filteredProfessions: string[] = [];
  filteredProvinces: string[] = [];

  selectedRowIndex: number = 0;
  displayedColumns: string[] = ['results'];
  showConcernsOnly = false;

  resultsMessage: string = '';
  initalSearchMessage: string = 'Select some search options to begin';
  noResultsMessage: string = 'No results found';
  noDBAvailableMessage: string = 'Database under construction';
  noResultsWithConcerns: string = `The result doesn't contain items with concerns`;

  // backend pagination control
  initialPageIndex: number = 0;
  initialPageSize: number = 20;
  initialTotalElements: number = 0;

  constructor(
    private providerService: ProviderService,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.resultsMessage = this.initalSearchMessage;

    const combinedbenefitSubscription = combineLatest([
      this.store.select(selectProfessionalsSearchOptions),
      this.store.select(selectProfessionalSelectedBenefit),
    ])
      .pipe(
        switchMap(([searchOptions, benefit]) => {
          if (searchOptions.provinces.length > 0) {
            this.professionalsSearchParam = searchOptions;
          }
          this.selectedBenefit = benefit;
          this.professionalSearch.benefits = [benefit];
          this.updateFiltersByBenefitSelected();
          this.selectedBenefitTabIndex =
            this.professionalsSearchParam.benefits.indexOf(benefit);
          return this.store.select(
            selectProfessionalsResultsByBenefit(benefit)
          );
        })
      )
      .subscribe((data) => {
        this.professionals = [];
        this.matDataSource.data = [];
        this.resultsMessage = ['Vision', 'Dental'].includes(
          this.selectedBenefit
        )
          ? this.noDBAvailableMessage
          : this.initalSearchMessage;

        if (this.professionalSearch.provinces.length > 0) {
          // if province is selected, display the preloaded data, if not, force a new search
          if (data?.length == 0) {
            this.resultsMessage = ['Vision', 'Dental'].includes(
              this.selectedBenefit
            )
              ? this.noDBAvailableMessage
              : this.noResultsMessage;
          }
          this.professionals = data || [];
          this.matDataSource.data = data;
          this.updateResutsByConcernsFilter();
        }
        this.autoSelectItemDetails();
      });

    const selectedItemSub = this.store
      .select(selectSelectedProfessionalDetails)
      .subscribe((professional) => {
        this.selectedProfessional = professional;
      });

    this.loadByIniaitalSearchQueryParams();
    this.subscription.add(combinedbenefitSubscription);
    this.subscription.add(selectedItemSub);
  }

  loadByIniaitalSearchQueryParams() {
    this.openDetailsByID = Number(this.route.snapshot.queryParamMap.get('id'));
    const name: string = this.route.snapshot.queryParamMap.get('n');
    const benefit: string = this.route.snapshot.queryParamMap.get('ben');
    const province: string = this.route.snapshot.queryParamMap.get('prov');
    const profession: string = this.route.snapshot.queryParamMap.get('prof');

    this.professionalSearch.name = name;
    this.professionalSearch.professions = profession ? [profession] : [];
    this.professionalSearch.provinces = province ? [province] : [];

    if (benefit || !this.selectedBenefit) {
      this.store.dispatch(
        setProfessionalSelectedBenefit({
          professionalSelectedBenefit: benefit
            ? benefit // set the one in params
            : this.professionalsSearchParam.benefits[0], // set the first in searchOptions
        })
      );
    }

    if (this.hasMinimumSearchQueryParams && !this.loading) {
      if (this.professionals.length == 0) {
        this.search();
      }
    }
  }

  searchPaginatedProfessionals(): void {
    this.loading = true;
    this.store.dispatch(showLoading());
    this.professionalSearch.pageSize =
      this.paginator?.pageSize || this.initialPageSize;
    this.professionalSearch.pageNumber =
      this.paginator?.pageIndex || this.initialPageIndex;

    this.providerService
      .searchPaginatedProfessionals(this.professionalSearch)
      .subscribe((professionalsResults: ProfessionalSearch) => {
        this.paginator.length = professionalsResults?.pageResult?.totalElements;
        const content = professionalsResults?.pageResult?.content;
        this.store.dispatch(
          setProfessionalsResultsByBenefit({
            benefit: this.selectedBenefit,
            professionalsResults: content,
          })
        );
        this.store.dispatch(hideLoading());
        this.loading = false;
        const notification_message =
          content.length > 0 ? 'Data fetched Successfully' : 'No results';
        this.notificationService.showNotification(
          notification_message,
          'Close',
          'success'
        );
      });
  }

  private autoSelectItemDetails() {
    const initialSelectedProfessional = this.selectedProfessional;
    let newSelectedProfessional = null;

    if (this.openDetailsByID && this.professionals.length > 0) {
      newSelectedProfessional =
        this.professionals.find((p) => p.id === this.openDetailsByID) ??
        this.professionals[0];
    } else if (initialSelectedProfessional && this.professionals.length > 0) {
      newSelectedProfessional = this.professionals[0];
    } else {
      newSelectedProfessional = null;
    }

    if (initialSelectedProfessional != newSelectedProfessional) {
      this.store.dispatch(
        setSelectedProfessionalDetails({
          selectedProfessionalDetails: newSelectedProfessional,
        })
      );
    }
  }

  search() {
    if (!this.professionalSearch.benefits[0]) {
      this.professionalSearch.benefits = [this.selectedBenefit];
    }
    this.searchPaginatedProfessionals();
    this.onCloseDetails();
    this.setRoute();
  }

  updateResutsByConcernsFilter() {
    if (this.showConcernsOnly) {
      this.matDataSource.data = this.professionals.filter(
        (row: any) => row.hasConcerns === true
      );
      if (
        this.professionals.length > 0 &&
        this.matDataSource.data.length == 0
      ) {
        this.resultsMessage = this.noResultsWithConcerns;
        if (this.selectedProfessional) {
          this.onCloseDetails();
        }
      }
    } else {
      this.matDataSource.data = this.professionals;
    }
  }

  clear() {
    this.professionalSearch = new ProfessionalSearch();
    this.setRoute();
    this.onCloseDetails();
    this.resultsMessage = this.initalSearchMessage;
    this.professionals = [];
    this.paginator.pageIndex = this.initialPageIndex;
    this.paginator.pageSize = this.initialPageSize;
    this.paginator.length = this.initialTotalElements;
    this.matDataSource.data = [];
  }

  private setRoute() {
    this.router.navigate([], {
      queryParams: {
        ben: this.professionalSearch.benefits,
        prof: this.professionalSearch.professions,
        prov: this.professionalSearch.provinces,
        n: this.professionalSearch.name,
      },
    });
  }

  onBenefitTabIndexChange(index: number): void {
    const newBenefit = this.professionalsSearchParam?.benefits[index];
    this.resultsMessage = ['Vision', 'Dental'].includes(newBenefit)
      ? this.noDBAvailableMessage
      : this.initalSearchMessage;

    this.store.dispatch(
      setProfessionalSelectedBenefit({
        professionalSelectedBenefit: newBenefit,
      })
    );
  }

  resetPreloadParams() {
    this.professionalSearch.provinces = [];
    this.professionalSearch.professions = [];
    this.professionalSearch.name = null;
  }

  onUserInteractChangeProvice(): void {
    this.userInteracted = true;
  }

  onProvinceSelected(value: string): void {
    if (this.userInteracted) {
      this.professionalSearch.professions = [];
      this.userInteracted = false;
    }
    this.filteredProfessions =
      this.benefitSearchGroupsSelected.find((prov) => prov.province === value)
        ?.professions || [];
  }

  openDetails(professional: Professional, index: number): void {
    this.selectedRowIndex = this.getActualIndex(index);
    this.store.dispatch(
      setSelectedProfessionalDetails({
        selectedProfessionalDetails: professional,
      })
    );
  }

  onCloseDetails(): void {
    this.store.dispatch(
      setSelectedProfessionalDetails({
        selectedProfessionalDetails: null,
      })
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.matDataSource.filter = filterValue.trim().toLowerCase();

    if (this.matDataSource.paginator) {
      this.matDataSource.paginator.firstPage();
    }
  }

  onKeydown(event: KeyboardEvent): void {
    if (this.matDataSource.data.length === 0) return;

    const maxIndex = this.getMaxIndexOnCurrentPage();
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    if (event.key === 'ArrowDown') {
      this.selectedRowIndex = Math.min(this.selectedRowIndex + 1, maxIndex);
    } else if (event.key === 'ArrowUp') {
      this.selectedRowIndex = Math.max(this.selectedRowIndex - 1, startIndex);
    }

    const newSelectedProfessional =
      this.matDataSource.data[this.selectedRowIndex];
    this.store.dispatch(
      setSelectedProfessionalDetails({
        selectedProfessionalDetails: newSelectedProfessional,
      })
    );
  }

  private getActualIndex(index: number): number {
    return this.paginator.pageIndex * this.paginator.pageSize + index;
  }

  private getMaxIndexOnCurrentPage(): number {
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    const endIndex = Math.min(
      startIndex + this.paginator.pageSize - 1,
      this.matDataSource.data.length - 1
    );
    return endIndex;
  }

  get hasMinimumSearchQueryParams(): boolean {
    const value =
      this.professionalSearch.provinces?.length > 0 ||
      this.professionalSearch.professions?.length > 0 ||
      this.professionalSearch.name?.trim().length > 0;
    return value;
  }

  private updateFiltersByBenefitSelected(): void {
    const benefitSearchGroupsSelected =
      this.professionalsSearchParam.benefitSearchGroups?.filter(
        (benefitGroup) => benefitGroup.benefit === this.selectedBenefit
      )[0]?.provinces || [];

    this.filteredProvinces =
      benefitSearchGroupsSelected?.map((prov) => prov?.province) || [];

    this.benefitSearchGroupsSelected = benefitSearchGroupsSelected;
    this.onProvinceSelected(this.professionalSearch.provinces[0]);
  }

  ngOnDestroy(): void {
    this.onCloseDetails();
    this.subscription.unsubscribe();
  }
}
