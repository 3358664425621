import { createSelector, createFeatureSelector } from '@ngrx/store';
import { ProviderState } from './providers.state';

export const selectProvidersState =
  createFeatureSelector<ProviderState>('providers');

export const selectProvidersSearchOptions = createSelector(
  selectProvidersState,
  (state) => state.providersSearchOptions
);

export const selectProvidersSelectedBenefit = createSelector(
  selectProvidersState,
  (state) => state.providersSelectedBenefit
);

export const selectProvidersResultsByBenefit = (benefit: string) =>
  createSelector(
    selectProvidersState,
    (state) => state.providersResultsData[benefit]
  );

export const selectProviderResultsError = createSelector(
  selectProvidersState,
  (state) => state.error
);

export const selectSelectedProviderDetails = createSelector(
  selectProvidersState,
  (state) => state.selectedProviderDetails
);

export const selectHasMinimumSearchQueryParams = createSelector(
  selectProvidersState,
  (state) => state.hasMinimumSearchQueryParams
);
