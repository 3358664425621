export interface AuthState {
  isAuthenticated: boolean;
  authToken: string | null;
  errorMessage: string | null;
}

export const initialAuthState: AuthState = {
  isAuthenticated: false,
  authToken: null,
  errorMessage: null,
};
